import { Box, Flex, Text } from '@chakra-ui/react';
import Avatar from '@ui-kit/atoms/avatar/avatar';
import { FC } from 'react';
import { sizesCardInfo } from './constants';
import * as CSS from 'csstype';
import { SpaceProps } from '@chakra-ui/styled-system';

type Props = {
  artistName: string;
  productName: string;
  genres: string;
  avatarUrl: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  m?: SpaceProps['m'];
};

export const CardInfo: FC<Props> = ({
  artistName,
  productName,
  genres,
  avatarUrl,
  size = 'md',
  m,
}) => {
  return (
    <Flex
      pt='20px'
      justifyContent='space-between'
      alignItems='center'
      m={m}
    >
      <Box maxW='245px'>
        <Text
          as='h3'
          textStyle={sizesCardInfo[size].artistNameStyle}
          color='text&icon.tx-contrast'
        >
          {artistName}
        </Text>

        <Text
          textStyle={sizesCardInfo[size].productNameStyle}
          color='text&icon.tx-contrast'
          mt='20px'
        >
          {productName}
        </Text>

        <Text
          mt='10px'
          textStyle={sizesCardInfo[size].genresStyle}
          color='text&icon.tx-secondary'
        >
          {genres}
        </Text>
      </Box>

      <Avatar
        size='md'
        name={artistName}
        src={avatarUrl}
      />
    </Flex>
  );
};

export default CardInfo;
