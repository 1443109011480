import type { CrowdFundingCampaignStatusEnum } from '@/shared/api/codegen/demuzo';
import { Flex, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { FC, MouseEvent } from 'react';
import { IconButton } from '../../atoms/icon-button';
import IconHeartFill from '../../icons/icon-heart-fill';
import IconHeartOutline from '../../icons/icon-heart-outline';
import { CampaignProgress } from '../../molecules/campaign-progress';
import { GradientBox } from '../../molecules/gradient-box';
import { ProjectCardCover } from '../../molecules/project-card-cover';
import { ProjectCardInfo } from '../../molecules/project-card-info';
import { Snippet } from '@/features/audio-player/types';

type Props = {
  onPlay: () => void;
  href: string;
  onToggleFavoriteFeature: (evt: MouseEvent<HTMLButtonElement>) => void;
  imageUrl: string;
  snippet?: Snippet;
  isFavorite: boolean;
  daysLeft: number;
  progress: number;
  artistName: string;
  productName: string;
  genres: string;
  progressToStart: number;
  totalInvested: number;
  limit: number;
  crowdFundingCampaignStatus: CrowdFundingCampaignStatusEnum;
};

export const ProjectCard: FC<Props> = ({
  onPlay,
  href,
  onToggleFavoriteFeature,
  imageUrl,
  snippet,
  isFavorite,
  daysLeft,
  progress,
  artistName,
  productName,
  genres,
  progressToStart,
  totalInvested,
  limit,
  crowdFundingCampaignStatus,
}) => {
  return (
    <GradientBox p='20px'>
      <Flex gap='20px'>
        <ProjectCardCover
          onPlay={onPlay}
          imageUrl={imageUrl}
          snippet={snippet}
          href={href}
        />

        <LinkBox
          as={Flex}
          direction='column'
          justify='space-between'
          w='full'
        >
          <Flex justify='space-between'>
            <LinkOverlay href={href}>
              <ProjectCardInfo
                genres={genres}
                productName={productName}
                artistName={artistName}
              />
            </LinkOverlay>

            <IconButton
              onClick={onToggleFavoriteFeature}
              variant='outline'
              colorScheme='white'
              border='none'
              aria-label='like'
              icon={isFavorite ? <IconHeartFill /> : <IconHeartOutline />}
              size='xs'
            />
          </Flex>

          <CampaignProgress
            crowdFundingCampaignStatus={crowdFundingCampaignStatus}
            progressToStart={progressToStart}
            totalInvested={totalInvested}
            daysLeft={daysLeft}
            limit={limit}
            progress={progress}
            progressVariant={'small'}
          />
        </LinkBox>
      </Flex>
    </GradientBox>
  );
};
