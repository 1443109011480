import { formatCurrency } from '@/shared/utils/format-currency';
import { Box, Flex, Text } from '@chakra-ui/react';
import Button from '@ui-kit/atoms/button/button';
import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import IconHeartFill from '@ui-kit/icons/icon-heart-fill';
import IconHeartOutline from '@ui-kit/icons/icon-heart-outline';
import { useTranslations } from 'next-intl';
import { FC, MouseEvent } from 'react';

type Props = {
  onToggleFavoriteFeature: (evt: MouseEvent<HTMLButtonElement>) => void;
  onBuy: () => void;
  isFavorite: boolean;
  m?: number | string;
  sharePrice: number;
  canBuy: boolean;
  revertSharePrice?: boolean;
  isFeatured?: boolean;
};

export const CardButtons: FC<Props> = ({
  onToggleFavoriteFeature,
  onBuy,
  isFavorite,
  m,
  canBuy,
  sharePrice,
  revertSharePrice,
  isFeatured,
}) => {
  const t = useTranslations();

  return (
    <Flex
      justifyContent='space-between'
      align='center'
      p={{ base: '20px 0 19px', lg: '0' }}
      gap='10px'
      m={m}
    >
      <Flex
        direction={revertSharePrice ? 'column-reverse' : 'column'}
        w='100px'
        flexShrink={0}
        width='fit-content'
        gap='10px'
      >
        <Text
          textStyle='text-medium-xs'
          color='text&icon.tx-secondary'
        >
          {t('CardButtons.sharePrice')}
        </Text>
        <Text
          textStyle='one-line-heading-large-xl'
          color='text&icon.tx-contrast'
          noOfLines={1}
        >
          {formatCurrency(sharePrice, true)}
        </Text>
      </Flex>

      <Box flex={1} />

      {canBuy ? (
        <Button
          onClick={onBuy}
          variant='solid'
          colorScheme='green'
          size='md'
          w='full'
          maxW='200px'
        >
          <Text textStyle='heading-large-sm'>{t('CardButtons.buyBtn')}</Text>
        </Button>
      ) : (
        <Box flex={1} />
      )}

      {(isFeatured || (!isFeatured && !canBuy)) && (
        <IconButton
          aria-label='heart'
          variant='outline'
          colorScheme='outline'
          onClick={onToggleFavoriteFeature}
          icon={isFavorite ? <IconHeartFill /> : <IconHeartOutline />}
        />
      )}
    </Flex>
  );
};

export default CardButtons;
