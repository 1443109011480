import type { CrowdFundingCampaignStatusEnum } from '@/shared/api/codegen/demuzo';
import { formatCurrency } from '@/shared/utils/format-currency';
import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { ProgressBar, ProgressBarVariant } from '../progress-bar/progress-bar';
import { useTranslations } from 'next-intl';

type Props = {
  crowdFundingCampaignStatus: CrowdFundingCampaignStatusEnum;
  progressToStart: number;
  totalInvested: number;
  daysLeft: number;
  limit: number;
  progress: number;
  mt?: string | number;
  rounded?: boolean;
  progressVariant: 'small' | 'default';
};

const progressBarVariantMap: Record<
  CrowdFundingCampaignStatusEnum,
  ProgressBarVariant
> = {
  canceled: 'yellow',
  finished: 'yellow',
  in_progress: 'yellow',
  planned: 'gray',
  suspended: 'orange',
};
export const CampaignProgress: FC<Props> = ({
  crowdFundingCampaignStatus,
  progressToStart,
  totalInvested,
  daysLeft,
  limit,
  progress,
  progressVariant,
  rounded = true,
  mt = '40px',
}) => {
  const t = useTranslations();

  return (
    <Flex
      direction='column'
      gap='10px'
      mt={mt}
    >
      <ProgressBar
        height={progressVariant === 'small' ? 3 : 10}
        progress={
          crowdFundingCampaignStatus === 'planned' ? progressToStart : progress
        }
        variant={progressBarVariantMap[crowdFundingCampaignStatus]}
        rounded={rounded && progressVariant === 'default'}
      />
      <Flex
        justify='space-between'
        position='relative'
      >
        {crowdFundingCampaignStatus !== 'planned' &&
          progressVariant === 'default' && (
            <Text
              textStyle='text-regular-mono-xs'
              color={
                crowdFundingCampaignStatus === 'canceled' ||
                crowdFundingCampaignStatus === 'suspended' ||
                totalInvested === 0
                  ? 'text&icon.tx-secondary'
                  : 'text&icon.tx-accent'
              }
            >
              {formatCurrency(totalInvested)}
            </Text>
          )}

        <Text
          position={{
            base: 'relative',
            lg:
              crowdFundingCampaignStatus === 'planned'
                ? 'relative'
                : 'absolute',
          }}
          left={0}
          right={0}
          textAlign={
            crowdFundingCampaignStatus === 'planned' ? 'left' : 'center'
          }
          textStyle='text-regular-mono-xs'
          color={
            crowdFundingCampaignStatus === 'suspended'
              ? 'text&icon.tx-warning'
              : 'text&icon.tx-secondary'
          }
        >
          {(crowdFundingCampaignStatus === 'in_progress' ||
            crowdFundingCampaignStatus === 'finished') &&
            t('CampaignProgress.daysToGo', { daysLeft })}
          {crowdFundingCampaignStatus === 'planned' &&
            t('CampaignProgress.daysBeforeStart', { daysLeft })}
          {crowdFundingCampaignStatus === 'suspended' &&
            t('CampaignProgress.suspended')}
          {crowdFundingCampaignStatus === 'canceled' &&
            t('CampaignProgress.notFunded')}
        </Text>

        {crowdFundingCampaignStatus !== 'planned' &&
          progressVariant === 'default' && (
            <Text
              textStyle='text-regular-mono-xs'
              color='text&icon.tx-secondary'
            >
              {t('CampaignProgress.of', { limit: formatCurrency(limit) })}
            </Text>
          )}
      </Flex>
    </Flex>
  );
};

export default CampaignProgress;
